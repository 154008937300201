<template>
    <NuxtLayout name="default">
        <GameMenu name="Blade Ball" image="/images/games/icons/bladeball.png" :links="links" />
        <NuxtLayout name="ads">
            <slot />
        </NuxtLayout>
    </NuxtLayout>
</template>

<script setup lang="ts">
const links = [
    { title: 'Swords', to: '/bladeball/swords' },
    { title: 'Explosions', to: '/bladeball/explosions' },
    { title: 'Emotes', to: '/bladeball/emotes' },
    { title: 'Trade Calculator', to: '/bladeball/calculate' },
    { title: 'Trade Ads', to: '/bladeball/ads' },
    { title: 'Value Changes', to: '/bladeball/value-changes' }
]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Blade Ball | RoValue` : 'Blade Ball - RoValue'
    }
})

useServerSeoMeta({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Blade Ball | RoValue` : 'Blade Ball - RoValue'
    }
})
</script>
